<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3">
          <sidebar-menu title="Dashboards" :items="dashboardPageSidebarMenuItems" />
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface"
        >
          <router-view/>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import SidebarMenu from "@/components/shared/SidebarMenu";

export default {
  name: 'Dashboard',
  components: {SidebarMenu},
  computed: {
    ...mapState("app",["loading","dashboardPageSidebarMenuItems"]),
  }
};
</script>
